import React, { useEffect,useState } from 'react';
import axios from "axios";
import Scrollbar from '../scrollbar/scrollbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./editprofile.scss";
import { baseURL } from '../../api';
import Footer from "../footer/Footer";
import Navbar from '../Navbar/Navbar';

const EditProfile = (props) =>{
    
    const [forms, setForms] = useState({
        user_id: '',
        name: '',
        gender: '',
        email: '',
    });

    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const onInputChange = (e) => {

        setForms({
            ...forms,
            [e.target.name]: e.target.value,
        });
        
    };

    const onSubmit = (e) => {
        let data = {
            user_id: forms.id,
            name: forms.name,
            gender: forms.gender,
            email: forms.email,
        };
        var v = document.getElementById("addrrms").checkValidity();
       
        e.preventDefault();
        if (v) {
            setIsButtonDisabled(true)
            axios({
              url: baseURL+"/update-website-user-by-id",
              method: "PUT",
              data,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
              },
            }).then((res) => {
                setForms(res.data.edituser);
              if(res.data.code === 200){
                toast.success('Data Updated Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                
              }else{
                toast.error('Data is not Updated Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                
              }
            });
          }
    };

    

    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
            headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
        };     
        fetch(baseURL+'/fetch-website-user-for-edit', requestOptions)
        .then(response => response.json())
        .then(data => setForms(data.edituser)); 
    }, []);
    
    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`formuseredit wpo-contact-area section-padding ${props.contactclass}`} id="formuseredit">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="row mt-4">
                        <div className="co-lg-12   col-12 mt-4">
                            <div className="row mt-4">
                                <div className="col col-lg-12 col-md-12 col-12">
                                    <div className="wpo-contact-form">
                                        <form id="addrrms"> 
                                            <div className='row'>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Full Name <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.name}
                                                            type="text"
                                                            name="name"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Gender <span>*</span></label>
                                                        <select name="gender" required style={{color:'Black'}} value={forms.gender} onChange={(e) => onInputChange(e)}>
                                                            <option selected disabled>Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Email <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.email}
                                                            type="email"
                                                            name="email"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)}>
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
            <Scrollbar/>
        </>
    )
}
export default EditProfile;