import React, { useEffect, useState } from "react";
import axios from "axios";
import "./home.scss";
import { baseURL } from '../../api';
import CircularProgress from "@material-ui/core/CircularProgress";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import 'react-toastify/dist/ReactToastify.css';
import Available from "../../images/available.png";
import Major from "../../images/mm.png";
import Occupy from "../../images/occupy.png";
import Maintenance from "../../images/um.png";

const Home = (props) =>{
    const [roomsNo, setRoomsNo] = useState({});
    const [roomsMaintenance, setMaintenance] = useState({});
    const [roomsOccupy, setOccupy] = useState({});
    const [roomsAvailable, setAvailable] = useState({});
    const [roomsTotal, setTotal] = useState({});
    const [loader, setLoader]= useState(true);
    
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
            setInterval(() => {
                axios({
                    url: baseURL+"/fetch-website-rooms-all-available",
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("login")}`,
                    },
                  }).then((res) => {
                      setRoomsNo(res.data.roomsno);
                      setOccupy(res.data.occupy);
                      setMaintenance(res.data.maintenance);
                      setAvailable(res.data.available);
                      setTotal(res.data.total);
                      setTotal(res.data.total);
                      setLoader(false)
                    
                  });
                
                
            }, 5000);
        }

        
    }, []);
  
    return(
        <>
            <section className={`homedashboard wpo-contact-area section-padding`} id="dashboard">
                <div className="wpo-wpo-contact-form-map ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="co-lg-12  col-md-12">
                                <div className="row">
                                    <div className="col col-lg-12 col-md-12 col-12">
                                        <div className="wpo-contact-form newhome">
                                            <div className="row" style={{textAlign:'center'}}>
                                                <div className="col-md-3">
                                                    <h6>TOTAL - {roomsTotal.room_total}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6>AVAILABLE - {roomsAvailable.room_available}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6>OCCUPY - {roomsOccupy.room_occupy}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6>MAINTENANCE - {roomsMaintenance.room_mintenance}</h6>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                {loader && (
                                                    <CircularProgress
                                                    disableShrink
                                                    style={{
                                                        marginLeft: "600px",
                                                        marginTop: "200px",
                                                        marginBottom: "200px",
                                                    }}
                                                    />
                                                )}
                                                {!loader && (
                                                    <MDBRow>
                                                        {roomsNo.map((current_data, index) => {
                                                            if (current_data.status_label == 'Occupy') {
                                                                return <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} ><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Occupy} style={{maxWidth:'75%'}} /></MDBCol>;
                                                            }else if(current_data.status_label == 'Maintenance'){
                                                                return <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} ><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Maintenance} style={{maxWidth:'75%'}}/></MDBCol>;
                                                            }else if(current_data.status_label == 'Major Maintenance'){
                                                                return <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}}><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Major} style={{maxWidth:'75%'}}/></MDBCol>;
                                                            }else{
                                                                return <MDBCol md='1'  className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}}><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Available} style={{maxWidth:'75%'}}/></MDBCol>;
                                                            }
                                                        })}
                                                        <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="CLI Room"><span style={{color:'#000',fontSize:'18px'}}>05</span> <br/><div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>CLI <br/>Room</div></MDBCol>
                                                        <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="Reception Room"><span style={{color:'#000',fontSize:'18px'}}>06</span> <br/> <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>Reception <br/> Room</div></MDBCol>
                                                        <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="CCC Office"><span style={{color:'#000',fontSize:'18px'}}>07</span> <br/> <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>CCC <br/> Office</div></MDBCol>
                                                        <MDBCol md='1' className="hmob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="C&W Staff"><span style={{color:'#000',fontSize:'18px'}}>08</span> <br/> <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>C&W <br/> Staff</div></MDBCol>
                                                    </MDBRow>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Home;