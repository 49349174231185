import React, { useState } from 'react';
import Scrollbar from '../../scrollbar/scrollbar';
import 'react-toastify/dist/ReactToastify.css';
import "./occupancyreportform.scss";
import { baseURL } from '../../../api';
import Footer from "../../footer/Footer";
import Navbar from '../../Navbar/Navbar';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const OccupancyReportForm = (props) =>{
    const navigate = useNavigate();
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    
    const [forms, setForms] = useState({
        br_from_date: '',
        br_to_date: date,
    });

    const onInputChange = (e) => {
        
            setForms({
                ...forms,
                [e.target.name]: e.target.value,
            });
        
    };

    const onSubmit = (e) => {
        var v = document.getElementById("addrrms").checkValidity();
        var v = document.getElementById("addrrms").reportValidity();
        e.preventDefault();
        if (v){
            localStorage.setItem('br_from_date',forms.br_from_date);
            localStorage.setItem('br_to_date',forms.br_to_date);
            
            navigate('/occupancy-report');
        }
        
    };

    const onDownload = (e) =>{
        var v = document.getElementById("addrrms").checkValidity();
        var v = document.getElementById("addrrms").reportValidity();
        e.preventDefault();
        let data = {
            br_from_date: forms.br_from_date,
            br_to_date: forms.br_to_date,
        };
        if (v){
            axios({
                url: baseURL+"/download-occupancy-report",
                method: "POST",
                data,
                headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
                },
            }).then((res) => {
                
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'occupancy_report.csv');
            document.body.appendChild(link);
            link.click();
            toast.success('Report is download Sucessfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
                
            }).catch((err) =>{
                toast.error('Failed to download', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            
            });
        }
    };

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`occupancyReportForm wpo-contact-area section-padding ${props.contactclass}`} id="cheangepassword">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="row mt-4">
                        <div className="co-lg-12   col-12 mt-4">
                            <div className="row mt-4">
                                <div className="col col-lg-12 col-md-12 col-12">
                                    <div className="wpo-contact-form">
                                    <h2>Occupancy Report</h2>
                                        <form id="addrrms"> 
                                            <div className='row'>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>From Date <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.br_from_date}
                                                            type="date"
                                                            name="br_from_date"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>To Date <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.br_to_date}
                                                            type="date"
                                                            name="br_to_date"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)} style={{marginRight:'20px'}}>
                                                    View
                                                </button>
                                                <button type="submit" className="theme-btn" onClick={(e) => onDownload(e)}>
                                                    Download
                                                </button>
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
            <Scrollbar/>
        </>
    )
}
export default OccupancyReportForm;