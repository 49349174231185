import React from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Dashboard from '../../components/Dashboard/Dashboard';
import Login from '../../components/Login/Login';
import OccupancyReportForm from '../../components/Reports/OccupancyReport/OccupancyReportForm';
import OccupancyReport from '../../components/Reports/OccupancyReport/OccupancyReport';
import OccupancySingleReportForm from '../../components/Reports/OccupancyReportSingle/OccupancySingleReportForm';
import OccupancySingleReport from '../../components/Reports/OccupancyReportSingle/OccupancySingleReport';
import ChangePassword from '../../components/Useres/ChangePassword';
import EditProfile from '../../components/Useres/EditProfile';
import MaintenanceReportForm from '../../components/Reports/MaintanceReport/MaintenanceReportForm';
import MaintenanceReport from '../../components/Reports/MaintanceReport/MaintenanceReport';
import Home from '../../components/Dashboard/Home';

const AllRoute = () => { 

  return (
    <div className="App">
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/dashboard' element={<Dashboard/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/occupancy-form' element={<OccupancyReportForm/>}/>
            <Route path='/occupancy-report' element={<OccupancyReport/>}/>
            <Route path='/occupancy-single-form' element={<OccupancySingleReportForm/>}/>
            <Route path='/occupancy-single-report' element={<OccupancySingleReport/>}/>
            <Route path='/maintenance-form' element={<MaintenanceReportForm/>}/>
            <Route path='/maintenance-report'  element={<MaintenanceReport/>}/>
            <Route path='/user-edit' element={<EditProfile/>}/>
            <Route path='/change-password' element={<ChangePassword/>}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default AllRoute;
