import React, { useEffect,useState } from 'react';
import axios from "axios";
import Scrollbar from '../scrollbar/scrollbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./changepassword.scss";
import { baseURL } from '../../api';
import Footer from "../footer/Footer";
import Navbar from '../Navbar/Navbar';

const ChangePassword = (props) =>{
    
    const [forms, setForms] = useState({
        user_id: '',
        oldpassword: '',
        newpassword: '',
        confirmpassword: ''
    });

    const onInputChange = (e) => {
        
            setForms({
                ...forms,
                [e.target.name]: e.target.value,
            });
        
    };

    const onSubmit = (e) => {
        if(forms.oldpassword == ''){
            toast.error('Fill Old Password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setForms({
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            });
            return false;
        }
        if(forms.newpassword == ''){
            toast.error('Fill New Password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setForms({
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            });
            return false;
        }
        if(forms.confirmpassword == ''){
            toast.error('Fill Confirm Password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setForms({
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            });
            return false;
        }
        if(forms.confirmpassword != forms.newpassword){
            toast.error('Password does not match', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setForms({
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            });
            return false;
        }
        let data = {
            user_id: forms.id,
            oldpassword: forms.oldpassword,
            newpassword: forms.newpassword
        };
        var v = document.getElementById("addrrms").checkValidity();
       
        e.preventDefault();
        if (v) {
            
            axios({
              url: baseURL+"/update-website-user-password",
              method: "PUT",
              data,
              headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
              },
            }).then((res) => {
                
              if(res.data.code === 200){
                toast.success('Password Change Sucessfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setForms({
                    oldpassword: '',
                    newpassword: '',
                    confirmpassword: ''
                });
                
              }else{
                toast.error('Failed to Change Password', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setForms({
                    oldpassword: '',
                    newpassword: '',
                    confirmpassword: ''
                });
                
              }
            });
        }
    };

    
    useEffect(() => {
        const requestOptions = {
            method: 'GET', 
            headers: {
                Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
        };     
        fetch(baseURL+'/fetch-website-user-for-edit', requestOptions)
        .then(response => response.json())
        .then(data => setForms(data.edituser)); 
    }, []);
    
    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`cheangepassword wpo-contact-area section-padding ${props.contactclass}`} id="cheangepassword">
            <div className="wpo-wpo-contact-form-map">
                <div className="container">
                    <div className="row mt-4">
                        <div className="co-lg-12   col-12 mt-4">
                            <div className="row mt-4">
                                <div className="col col-lg-12 col-md-12 col-12">
                                    <div className="wpo-contact-form">
                                        <form id="addrrms"> 
                                            <div className='row'>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Old Password <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.oldpassword}
                                                            type="password"
                                                            name="oldpassword"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>New Password <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.newpassword}
                                                            type="password"
                                                            name="newpassword"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-4 col-12">
                                                    <div className="form-field">
                                                        <label className='label'>Confirm Password <span>*</span></label>
                                                        <input
                                                            style={{color:'Black'}}
                                                            required
                                                            value={forms.confirmpassword}
                                                            type="password"
                                                            name="confirmpassword"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn" onClick={(e) => onSubmit(e)}>
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
            <Scrollbar/>
        </>
    )
}
export default ChangePassword;