import React from 'react';
import "./footer.scss";

const Footer = (props) => {
    return (
        <footer className="wpo-site-footer">
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <p className="copyright">Copyright &copy; 2023 <a href="https://ag-solutions.in/">AG Solutions.</a> All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;