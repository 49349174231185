import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { RctCard } from "../../RctCard/index";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'moment';
import {  savePDF } from '@progress/kendo-react-pdf';
import { baseURL } from '../../../api';
import "./occupancyreport.scss";
import Navbar from '../../Navbar/Navbar';
import Footer from "../../footer/Footer";
import Scrollbar from "../../scrollbar/scrollbar";

const table_head = {
    border: "1px solid black",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px"
  };

  const table_row_center = {
    textAlign: "center",
    border: "1px solid black",
  };
  
  const table_row_start = {
    textAlign: "start",
    border: "1px solid black",
  };

const OccupancyReport = (props) =>{
    const componentRef = useRef();
    const [rest, setRest] = useState([]);
    const [loader, setLoader]= useState(true);
    

    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        
        }
        let data = {
            br_from_date:localStorage.getItem("br_from_date"),
            br_to_date: localStorage.getItem("br_to_date"), 
        };
        
        axios({
          url: baseURL+"/fetch-occupancy-report/"+localStorage.getItem("br_from_date")+"/"+localStorage.getItem("br_to_date"),
          method: "GET",
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
          setRest(res.data.bedrequest);
          setLoader(false)
        });
    }, []);

    const  handleExportWithFunction  = (e) => {
        savePDF(componentRef.current, { 
          paperSize:  "A4", 
          orientation: "vertical",
            scale: 0.8,
        });
    }

    return (
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`reports wpo-contact-area section-padding`} id="reports" >
                <div className="wpo-wpo-contact-form-map">
                    <div className="container crardsview">
                        <div className="row  mt-4" style={{justifyContent:'center'}}>
                            <div className="co-lg-12   col-md-12">
                                {loader && (
                                    <CircularProgress
                                    disableShrink
                                    style={{
                                        marginLeft: "600px",
                                        marginTop: "200px",
                                        marginBottom: "200px",
                                    }}
                                    />
                                )}
                                {!loader && (
                                    <RctCard>
                                        <div className="invoice-head text-right">
                                            <ul className="list-inline mt-2">
                                                <li style={{padding:'10px',cursor:'pointer',border:'1px solid #e8e3e3',color:'black'}}>
                                                    From : {Moment(localStorage.getItem("br_from_date")).format('DD-MM-YYYY')} To : {Moment(localStorage.getItem("br_to_date")).format('DD-MM-YYYY')}
                                                </li>
                                                <li style={{padding:'10px',cursor:'pointer',border:'1px solid #e8e3e3'}}>
                                                    <a onClick={(e) => handleExportWithFunction(e)}>
                                                        <i className="mr-10 ti-download"></i> PDF
                                                    </a>
                                                </li>
                                                <li style={{padding:'10px',cursor:'pointer',border:'1px solid #e8e3e3',marginRight:'10px'}}>
                                                    <ReactToPrint
                                                        trigger={() => (
                                                            <a>
                                                                <i className="mr-10 ti-printer"></i> Print
                                                            </a>
                                                        )}
                                                        content={() => componentRef.current}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="p-10" ref={componentRef} style={{margin: '5px'}}>
                                            <div className="d-flex justify-content-between" style={{fontSize: '16px' }}>
                                                <h2>Occupancy Reports</h2>
                                            </div>
                                            <div className="table-responsive mt-4">
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table" style={{border: '2px solid black'}}>
                                                        <TableHead>          
                                                            <TableRow>
                                                                <TableCell style={table_head}>Full Name</TableCell>
                                                                <TableCell style={table_head}>Employment ID</TableCell>
                                                                <TableCell style={table_head}>Room No</TableCell>
                                                                <TableCell style={table_head}>Occupy Date</TableCell>
                                                                <TableCell style={table_head}>Occupy Time</TableCell>
                                                                <TableCell style={table_head}>Vacate Date</TableCell>
                                                                <TableCell style={table_head}>Vacate Time</TableCell>
                                                                <TableCell style={table_head}>Remarks</TableCell>
                                                                <TableCell style={table_head}>Status</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rest.map((datalist, key) => (
                                                                <TableRow key={datalist.br_user_name}>
                                                                    <TableCell style={table_row_start}>{datalist.br_user_name}</TableCell>
                                                                    <TableCell style={table_row_center}>{datalist.br_user_id}</TableCell>
                                                                    <TableCell style={table_row_center}>{datalist.br_room_no}</TableCell>
                                                                    <TableCell style={table_row_center}>{Moment(datalist.br_date).format('DD-MM-YYYY')}</TableCell>
                                                                    <TableCell style={table_row_center}>{datalist.br_time}</TableCell>
                                                                    <TableCell style={table_row_center}>
                                                                        {
                                                                            datalist.br_out_date !== null &&(
                                                                                Moment(datalist.br_out_date).format('DD-MM-YYYY')
                                                                            )
                                                                        }
                                                                        
                                                                    </TableCell>
                                                                    <TableCell style={table_row_center}>{datalist.br_out_time}</TableCell>
                                                                    <TableCell style={table_row_start}>{datalist.br_remarks}</TableCell>
                                                                    <TableCell style={table_row_center}>{datalist.br_status}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </RctCard>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Scrollbar/>
            <Footer />
        </>
    )
}
export default OccupancyReport;