import React, { useEffect, useState } from "react";
import axios from "axios";
import "./dashboard.scss";
import { baseURL } from '../../api';
import Navbar from '../Navbar/Navbar';
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from "reactstrap";
import Available from "../../images/available.png";
import Major from "../../images/mm.png";
import Occupy from "../../images/occupy.png";
import Maintenance from "../../images/um.png";
import AssignIcon from "../../images/a.png";
import CleanIcon from "../../images/b.png";
import MaintenanceIcon from "../../images/c.png";

const Dashboard = (props) =>{
    const [roomsNo, setRoomsNo] = useState({});
    const [roomsMaintenance, setMaintenance] = useState({});
    const [roomsOccupy, setOccupy] = useState({});
    const [roomsAvailable, setAvailable] = useState({});
    const [roomsTotal, setTotal] = useState({});
    const [loader, setLoader]= useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    const [showmodal, setShowmodal] = useState(false);
    const [showmodalEdit, setShowmodalEdit] = useState(false);
    const [showmodalM, setShowmodalM] = useState(false);
    const [showmodalMM, setShowmodalMM] = useState(false);
    const [style, setStyle] = useState(false);

    const closegroupModal = () => {
        setShowmodal(false);
    };

    const closeModalEdit = () => {
        setShowmodalEdit(false);
    };

    const closeModalM = () => {
        setShowmodalM(false);
    };

    const closeModalMM = () => {
        setShowmodalMM(false);
    };
      
    const openmodal = (value) => {
        localStorage.setItem("New_Room",value);
        setShowmodal(true);
    };

    const openmodalEdit = (value) => {
        localStorage.setItem("Old_Room",value);
        setShowmodalEdit(true);
    };
    const openmodalM = (value) => {
        localStorage.setItem("M_Room",value);
        setShowmodalM(true);
    };
    const openmodalMM = (value) => {
        localStorage.setItem("MM_Room",value);
        setShowmodalMM(true);
    };

    const maintenance = (e, value) => {
        e.preventDefault();
        axios({
            url: baseURL+"/update-website-room-status/" + value,
            method: "PUT",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
        }).then((res) => {
            
            setRoomsNo(res.data.roomsno);
            setOccupy(res.data.occupy);
            setMaintenance(res.data.maintenance);
            setAvailable(res.data.available);
            setTotal(res.data.total);
            setIsButtonDisabled(false)
            closegroupModal();
            toast.success('Maintenance is Updated Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
          })
          .catch((error) => {
            
            setIsButtonDisabled(false)
            closegroupModal();
            toast.error('Failed to Update Maintenance', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
          });
    }

    const [newRoom, setNewRoom] = useState({
        br_room_no: localStorage.getItem("New_Room"),
        br_user_name: "",
        br_user_id: "",
        br_date: "",
        br_time: "",
        br_remarks: "",
    });

    const [oldRoom, setOldRoom] = useState({
        br_out_date: "",
        br_out_time: "",
        br_remarks: "",
    });

    const [mRoom, setMRoom] = useState({
        m_remarks: "",
    });

    const [mmRoom, setMMRoom] = useState({
        m_remarks: "",
    });

    const onNewInputChange = (e) => {
        setNewRoom({
            ...newRoom,
        [e.target.name]: e.target.value,
        });
        
    };

    const onNewInputChangeM = (e) => {
        setMRoom({
            ...mRoom,
        [e.target.name]: e.target.value,
        });
        
    };

    const onNewInputChangeMM = (e) => {
        setMMRoom({
            ...mmRoom,
        [e.target.name]: e.target.value,
        });
        
    };

    const onOldInputChange = (e) => {
        setOldRoom({
            ...oldRoom,
        [e.target.name]: e.target.value,
        });
        
    };
    
    useEffect(() => {
        var isLoggedIn = localStorage.getItem("id");
        if(!isLoggedIn){
          window.location = "/login";
        }else{
        }
        
        axios({
          url: baseURL+"/fetch-website-rooms-all-available",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        }).then((res) => {
            setRoomsNo(res.data.roomsno);
            setOccupy(res.data.occupy);
            setMaintenance(res.data.maintenance);
            setAvailable(res.data.available);
            setTotal(res.data.total);
            setTotal(res.data.total);
            setLoader(false)
          
        });
    }, []);
    
    const createRoom = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true)
        let data = {
            br_room_no: localStorage.getItem("New_Room"),
            br_user_name: newRoom.br_user_name,
            br_user_id: newRoom.br_user_id,
            br_date: newRoom.br_date,
            br_time: newRoom.br_time,
            br_remarks: newRoom.br_remarks,
        };
     
        axios({
          url: baseURL+"/create-website-assign-room",
          method: "POST",
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        })
          .then((res) => {
            e.target.reset();
            setRoomsNo(res.data.roomsno);
            setOccupy(res.data.occupy);
            setMaintenance(res.data.maintenance);
            setAvailable(res.data.available);
            setTotal(res.data.total);
            setIsButtonDisabled(false)
            closegroupModal();
            toast.success('Room is Assign Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
          })
          .catch((error) => {
            e.target.reset();
            setIsButtonDisabled(false)
            closegroupModal();
            toast.error('Failed to Assign Room', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        });
    };

    const createM = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true)
        let data = {
            m_room_no: localStorage.getItem("M_Room"),
            m_remarks: mRoom.m_remarks,
            
        };
     
        axios({
          url: baseURL+"/create-website-maintance",
          method: "POST",
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        })
          .then((res) => {
            e.target.reset();
            setRoomsNo(res.data.roomsno);
            setOccupy(res.data.occupy);
            setMaintenance(res.data.maintenance);
            setAvailable(res.data.available);
            setTotal(res.data.total);
            setIsButtonDisabled(false)
            closeModalM();
            toast.success('Room is under Maintenance', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
          })
          .catch((error) => {
            e.target.reset();
            setIsButtonDisabled(false)
            closeModalM();
            toast.error('Failed to Maintenance Room', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        });
    };

    const createMM = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true)
        let data = {
            m_room_no: localStorage.getItem("MM_Room"),
            m_remarks: mmRoom.m_remarks,
            
        };
     
        axios({
          url: baseURL+"/create-website-major-maintance",
          method: "POST",
          data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("login")}`,
          },
        })
          .then((res) => {
            e.target.reset();
            setRoomsNo(res.data.roomsno);
            setOccupy(res.data.occupy);
            setMaintenance(res.data.maintenance);
            setAvailable(res.data.available);
            setTotal(res.data.total);
            setIsButtonDisabled(false)
            closeModalMM();
            toast.success('Room is under Major Maintenance', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
          })
          .catch((error) => {
            e.target.reset();
            setIsButtonDisabled(false)
            closeModalMM();
            toast.error('Failed to Maintenance Room', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        });
    };

    const updateRoom = (e) =>{
        e.preventDefault();
        setIsButtonDisabled(true)
        let data = {
            br_out_date: oldRoom.br_out_date,
            br_out_time: oldRoom.br_out_time,
            br_remarks: oldRoom.br_remarks,
        };
        axios({
            url: baseURL+"/update-website-assign-room/"+localStorage.getItem("Old_Room"),
            method: "PUT",
            data,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("login")}`,
            },
          })
            .then((res) => {
              e.target.reset();
              setRoomsNo(res.data.roomsno);
              setOccupy(res.data.occupy);
            setMaintenance(res.data.maintenance);
            setAvailable(res.data.available);
            setTotal(res.data.total);
              setIsButtonDisabled(false)
              closeModalEdit();
              toast.success('Room is Vacated Successfully', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
            })
            .catch((error) => {
              e.target.reset();
              setIsButtonDisabled(false)
              closeModalEdit();
              toast.error('Failed to Vacate Room', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
            });
    };

    return(
        <>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
            <section className={`dashboard wpo-contact-area section-padding`} id="dashboard">
                <div className="wpo-wpo-contact-form-map">
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="co-lg-12  col-md-12">
                                <div className="row mt-4">
                                    <div className="col col-lg-12 col-md-12 col-12 mt-4">
                                        <div className="wpo-contact-form mt-4">
                                            <div className="row" style={{textAlign:'center'}}>
                                                <div className="col-md-3">
                                                    <h6>TOTAL - {roomsTotal.room_total}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6>AVAILABLE - {roomsAvailable.room_available}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6>OCCUPY - {roomsOccupy.room_occupy}</h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <h6>MAINTENANCE - {roomsMaintenance.room_mintenance}</h6>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                {loader && (
                                                    <CircularProgress
                                                    disableShrink
                                                    style={{
                                                        marginLeft: "600px",
                                                        marginTop: "200px",
                                                        marginBottom: "200px",
                                                    }}
                                                    />
                                                )}
                                                {!loader && (
                                                    <MDBRow>
                                                        {roomsNo.map((current_data, index) => {
                                                            if (current_data.status_label == 'Occupy') {
                                                                return <MDBCol md='1' id={index} className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="To Vacate" onClick={(e) => openmodalEdit(current_data.rooms_no)}><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Occupy} style={{maxWidth:'75%'}}/></MDBCol>;
                                                            }else if(current_data.status_label == 'Maintenance'){
                                                                return <MDBCol md='1' id={index} className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="To Available" onClick={(e) => maintenance(e,current_data.rooms_no)} ><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Maintenance} style={{maxWidth:'75%'}} /></MDBCol>;
                                                            }else if(current_data.status_label == 'Major Maintenance'){
                                                                return <MDBCol md='1' id={index} className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="To Available" onClick={(e) => maintenance(e,current_data.rooms_no)} ><span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img src={Major} style={{maxWidth:'75%'}}/></MDBCol>;
                                                            }else{
                                                                return <MDBCol md='1' id={index} className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}}
                                                                onMouseEnter={() => setStyle(index)}
                                                                onMouseLeave={() => setStyle(false)}
                                                                >
                                                                    <span style={{color:'#000',fontSize:'18px'}}>{current_data.rooms_no}</span> <br/> <img className={style === index ? "img_new" : ""} src={Available} style={{maxWidth:'75%'}}/>
                                                                        <div className={style === index ? "displayed" : "notdisplayed"} style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',paddingTop:'10px'}}>
                                                                            <div style={{paddingRight : '10px'}}>
                                                                                <img src={AssignIcon} title="To Book Room" style={{cursor:'pointer',width:'18px'}} onClick={(e) => openmodal(current_data.rooms_no)}/>
                                                                            </div>
                                                                            <div style={{paddingRight : '10px'}}>
                                                                                <img src={CleanIcon} title="To Maintenance" style={{cursor:'pointer',width:'18px'}} onClick={(e) => openmodalM(current_data.rooms_no)}/>
                                                                            </div>
                                                                            <div>
                                                                                <img src={MaintenanceIcon} title="To Major Maintenance" style={{cursor:'pointer',width:'18px'}} onClick={(e) => openmodalMM(current_data.rooms_no)}/>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCol>;
                                                            }
                                                             
                                                        })}
                                                        <MDBCol md='1' className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="CLI Room"><span style={{color:'#000',fontSize:'18px'}}>05</span> <br/><div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>CLI <br/>Room</div></MDBCol>
                                                        <MDBCol md='1' className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="Reception Room"><span style={{color:'#000',fontSize:'18px'}}>06</span> <br/> <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>Reception <br/> Room</div></MDBCol>
                                                        <MDBCol md='1' className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="CCC Office"><span style={{color:'#000',fontSize:'18px'}}>07</span> <br/> <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>CCC <br/> Office</div></MDBCol>
                                                        <MDBCol md='1' className="mob" style={{textAlign:'center',color:'#000',border:'1px solid #807575',padding:'10px',fontSize:'13px',cursor:'pointer',boxShadow:'3px 3px 6px lightgray',margin:'10px',borderRadius:'10px'}} title="C&W Staff"><span style={{color:'#000',fontSize:'18px'}}>08</span> <br/> <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',fontSize:'16px'}}>C&W <br/> Staff</div></MDBCol>
                                                    </MDBRow>
                                                )}
                                            </div>
                                            <Modal isOpen={showmodal}  style={{top:'100px', maxWidth: '630px'}}>
                                                <ModalHeader style={{justifyContent:'center',backgroundColor:'#2f2f8f',color:'#fff'}}>
                                                    ASSIGN ROOM
                                                </ModalHeader>
                                                <ModalBody style={{backgroundColor:'#dedef7'}}>
                                                    <form
                                                        autoComplete="off"
                                                        onSubmit={(e) => {
                                                        createRoom(e);
                                                        }}
                                                    >
                                                        <div className="row" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-6 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Full Name <span>*</span></label>
                                                                    <input
                                                                        style={{color:'Black'}}
                                                                        required
                                                                        className="form-control"
                                                                        value={newRoom.br_user_name}
                                                                        type="text"
                                                                        name="br_user_name"
                                                                        onChange={(e) => onNewInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col col-lg-6 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Employment  ID <span>*</span></label>
                                                                    <input
                                                                        style={{color:'Black'}}
                                                                        className="form-control"
                                                                        required
                                                                        value={newRoom.br_user_id}
                                                                        type="text"
                                                                        name="br_user_id"
                                                                        onChange={(e) => onNewInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-6 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Occupy Date <span>*</span></label>
                                                                    <input
                                                                        style={{color:'Black'}}
                                                                        required
                                                                        className="form-control"
                                                                        value={newRoom.br_date}
                                                                        type="date"
                                                                        name="br_date"
                                                                        onChange={(e) => onNewInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col col-lg-6 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Occupy Time <span>*</span></label>
                                                                    <input
                                                                        style={{color:'Black'}}
                                                                        required
                                                                        min="09:00" max="18:00"
                                                                        className="form-control"
                                                                        value={newRoom.br_time}
                                                                        type="time"
                                                                        name="br_time"
                                                                        onChange={(e) => onNewInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-12 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Remarks</label><br/>
                                                                    <input
                                                                        style={{color:'Black',height:'80px'}}
                                                                        className="form-control"
                                                                        value={newRoom.br_remarks}
                                                                        type="text"
                                                                        name="br_remarks"
                                                                        onChange={(e) => onNewInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col col-lg-12 col-12" style={{display:'flex',justifyContent:'space-evenly'}}>
                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isButtonDisabled}
                                                                >
                                                                    Submit
                                                                </Button>

                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => closegroupModal()}
                                                                    disabled={isButtonDisabled}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </ModalBody>
                                            </Modal>

                                            <Modal isOpen={showmodalEdit} style={{top:'100px', maxWidth: '630px'}}>
                                                <ModalHeader style={{justifyContent:'center',backgroundColor:'#2f2f8f',color:'#fff'}}>
                                                    VACATE ROOM
                                                </ModalHeader>
                                                <ModalBody style={{backgroundColor:'#dedef7'}}>
                                                    <form
                                                        autoComplete="off"
                                                        onSubmit={(e) => {
                                                        updateRoom(e);
                                                        }}
                                                    >
                                                        <div className="row" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-6 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Vacate Date <span>*</span></label>
                                                                    <input
                                                                        style={{color:'Black'}}
                                                                        required
                                                                        className="form-control"
                                                                        value={oldRoom.br_out_date}
                                                                        type="date"
                                                                        name="br_out_date"
                                                                        onChange={(e) => onOldInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col col-lg-6 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Vacate Time <span>*</span></label>
                                                                    <input
                                                                        style={{color:'Black'}}
                                                                        required
                                                                        className="form-control"
                                                                        value={oldRoom.br_out_time}
                                                                        type="time"
                                                                        name="br_out_time"
                                                                        onChange={(e) => onOldInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-12 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Remarks</label><br/>
                                                                    <input
                                                                        style={{color:'Black',height:'80px'}}
                                                                        className="form-control"
                                                                        value={oldRoom.br_remarks}
                                                                        type="text"
                                                                        name="br_remarks"
                                                                        onChange={(e) => onOldInputChange(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col col-lg-12 col-12" style={{display:'flex',justifyContent:'space-evenly'}}>
                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isButtonDisabled}
                                                                >
                                                                    Submit
                                                                </Button>

                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => closeModalEdit()}
                                                                    disabled={isButtonDisabled}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </ModalBody>
                                            </Modal>

                                            <Modal isOpen={showmodalM} style={{top:'100px', maxWidth: '630px'}}>
                                                <ModalHeader style={{justifyContent:'center',backgroundColor:'#2f2f8f',color:'#fff'}}>
                                                    MAINTENANCE ROOM
                                                </ModalHeader>
                                                <ModalBody style={{backgroundColor:'#dedef7'}}>
                                                    <form
                                                        autoComplete="off"
                                                        onSubmit={(e) => {
                                                            createM(e);
                                                            }}
                                                    >
                                                        <div className="row" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-12 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Remarks</label><br/>
                                                                    <input
                                                                        style={{color:'Black',height:'80px'}}
                                                                        className="form-control"
                                                                        value={mRoom.m_remarks}
                                                                        type="text"
                                                                        name="m_remarks"
                                                                        onChange={(e) => onNewInputChangeM(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col col-lg-12 col-12" style={{display:'flex',justifyContent:'space-evenly'}}>
                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isButtonDisabled}
                                                                    
                                                                >
                                                                    Submit
                                                                </Button>

                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => closeModalM()}
                                                                    disabled={isButtonDisabled}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </ModalBody>
                                            </Modal>

                                            <Modal isOpen={showmodalMM}  style={{top:'100px', maxWidth: '630px'}}>
                                                <ModalHeader style={{justifyContent:'center',backgroundColor:'#2f2f8f',color:'#fff'}}>
                                                    MAJOR MAINTENANCE ROOM
                                                </ModalHeader>
                                                <ModalBody style={{backgroundColor:'#dedef7'}}>
                                                    <form
                                                        autoComplete="off"
                                                        onSubmit={(e) => {
                                                            createMM(e);
                                                            }}
                                                    >
                                                        <div className="row" style={{paddingRight:'10px',paddingLeft:'10px'}}>
                                                            <div className="col col-lg-12 col-12">
                                                                <div className="form-field">
                                                                    <label className='label'>Please Enter Reason</label><br/>
                                                                    <input
                                                                        style={{color:'Black',height:'80px'}}
                                                                        className="form-control"
                                                                        value={mmRoom.m_remarks}
                                                                        type="text"
                                                                        name="m_remarks"
                                                                        onChange={(e) => onNewInputChangeMM(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col col-lg-12 col-12" style={{display:'flex',justifyContent:'space-evenly'}}>
                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={isButtonDisabled}
                                                                    
                                                                >
                                                                    Submit
                                                                </Button>
                                                                <Button
                                                                    className="mr-10 mb-10 new_button"
                                                                    color="primary"
                                                                    type="button"
                                                                    onClick={() => closeModalMM()}
                                                                    disabled={isButtonDisabled}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </div>
                                                            
                                                        </div>
                                                    </form>
                                                </ModalBody>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Scrollbar/>
            <Footer />
        </>
    )
}
export default Dashboard;