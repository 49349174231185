import React from 'react'
import { Link } from "react-router-dom";
import MobileMenu from '../MobileMenu/MobileMenu'
import Logo from '../../images/logo.png'
import "./header.scss";

const Header = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const logoutUser = () => {
        
        localStorage.removeItem('login');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('user_type_id');
        window.location = "/login";
    }
    
    const openLink = (e) => {
        const newWindow = window.open(
            "https://runningroom.in/home",
            "_blank",
            "width=1400,height=1000"
            
        );

        if (newWindow) {
            newWindow.focus();
        }
    };

    return (
        <header id="header" className={`wpo-header-style-1 ${props.hClass}`}>
            <div className="wpo-site-header">
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <MobileMenu/>
                            </div>
                            <div className="col-lg-1 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand site-logo" to="/dashboard">
                                        <img src={Logo} alt="" style={{height:'70px'}}/>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-1 col-1" style={{width:'90%'}}>
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li><Link to="/dashboard">Dashboard</Link></li>
                                        <li><a onClick={(e) => openLink(e)} target="_blank">Full Screen</a></li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Profile
                                            </a>
                                            <ul className='dropdown-menu drop-user'>
                                                <li><Link to="/user-edit" >Edit Profile</Link></li>
                                                <li><Link to="/change-password" >Change Password</Link></li>
                                                <li><Link className="dropdown-item" onClick={logoutUser}>Logout</Link></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Reports
                                            </a>
                                            <ul className='dropdown-menu drop-user'>
                                                <li><Link to="/occupancy-form" >Occupancy Report</Link></li>
                                                <li><Link to="/occupancy-single-form" >Occupancy Single</Link></li>
                                                <li><Link to="/maintenance-form" >Maintenance</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;